<template>
  <div class="survey-main">
    <!-- 第一屏 -->
    <div class="survey-top">
      <div class="title">{{ $t("survey.page1.title1") }}</div>
      <div class="tips">{{ $t("survey.page1.title2") }}</div>
    </div>
    <!-- 第二屏 -->
    <div class="survey-second">
      <div class="survey-box">
        <div class="left">
          <div class="box">
            <div class="bold-title">{{ $t("survey.page2.left.title1") }}</div>
            <div class="tips">{{ $t("survey.page2.left.tips1") }}</div>
          </div>
          <div class="box">
            <div class="bold-title">{{ $t("survey.page2.left.title2") }}</div>
            <div class="tips">{{ $t("survey.page2.left.tips2") }}</div>
            <div class="tips">{{ $t("survey.page2.left.tips3") }}</div>
          </div>
        </div>
        <div class="centre">
          <img src="@/assets/survey/bg2.png" />
          <div
            :class="[
              'centre-title',
              { 'centre-title2': $i18n.locale === 'en' },
            ]"
          >
            {{ $t("survey.page2.centre.title1") }}
          </div>
        </div>
        <div class="right">
          <div class="box">
            <div class="bold-title">{{ $t("survey.page2.right.title1") }}</div>
            <div class="tips">{{ $t("survey.page2.right.tips1") }}</div>
            <div class="tips">{{ $t("survey.page2.right.tips2") }}</div>
          </div>
          <div class="box">
            <div class="bold-title">{{ $t("survey.page2.right.title2") }}</div>
            <div class="tips">{{ $t("survey.page2.right.tips3") }}</div>
            <div class="tips">{{ $t("survey.page2.right.tips4") }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业定制服务流程 -->
    <div class="enterprise">
      <div class="title">{{ $t("survey.page3.title1") }}</div>
      <div class="tips">{{ $t("survey.page3.tips1") }}</div>
      <div class="tips">{{ $t("survey.page3.tips2") }}</div>
      <div class="tips">{{ $t("survey.page3.tips3") }}</div>
      <div class="boxs">
        <div class="box-item" v-for="item in 6" :key="item">
          <div :class="['name-box', { active: item === 1 }]">
            {{ $t(`survey.page3.box${item}`) }}
          </div>
          <img v-if="item !== 6" src="@/assets/survey/arrow.png" />
        </div>
      </div>
      <div class="boxs2">
        <div class="box-item" v-for="item in 6" :key="item">
          <div :class="['name-box', { active: item === 1 || item === 6 }]">
            {{ $t(`survey.page3.box${item}`) }}
          </div>
          <img v-if="item !== 6" src="@/assets/survey/arrow2.png" />
        </div>
      </div>
    </div>
    <!-- 你的需求 -->
    <div class="demand">
      <div class="title">{{ $t("survey.page4.title1") }}</div>

      <div class="boxs">
        <el-input
          v-model="input1"
          :placeholder="`${
            $i18n.locale === 'zn'
              ? '请输入企业名称'
              : 'Please enter the company name'
          }`"
        ></el-input>
        <el-input
          v-model="input2"
          :placeholder="`${
            $i18n.locale === 'zn' ? '请输入姓名' : 'Please enter your name'
          }`"
        ></el-input>
        <el-select
          v-model="value1"
          :placeholder="`${
            $i18n.locale === 'zn' ? '请选择行业' : 'Please select an industry'
          }`"
        >
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="$i18n.locale === 'zn' ? item.label : item.elabel"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="boxs">
        <el-select
          v-model="value2"
          :placeholder="`${
            $i18n.locale === 'zn' ? '请选择地区' : 'Please select a region'
          }`"
        >
          <el-option
            v-for="item in options2"
            :key="item.value"
            :label="$i18n.locale === 'zn' ? item.label : item.elabel"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="input3"
          :placeholder="`${
            $i18n.locale === 'zn'
              ? '请输入手机号'
              : 'Please enter your phone number'
          }`"
        ></el-input>
        <el-input
          v-model="input4"
          :placeholder="`${
            $i18n.locale === 'zn'
              ? '请输入邮箱'
              : 'Please enter your email address'
          }`"
        ></el-input>
      </div>

      <div class="boxs">
        <el-input
          class="textarea"
          type="textarea"
          :rows="2"
          :placeholder="`${
            $i18n.locale === 'zn'
              ? '留下您的需求，获取解决方案'
              : 'Leave your needs and obtain solutions'
          }`"
          v-model="textarea"
        >
        </el-input>
      </div>

      <button @click="submit">{{ $i18n.locale === "zn" ? "立即提交" : "Submit" }}</button>
    </div>
  </div>
</template>
<script>
import { setGuestbookAdd } from "@/api/app";
import { Message } from "element-ui";
export default {
  data() {
    return {
      options1: [
        {
          value: 1,
          label: "互联网",
          elabel: "internet",
        },
        {
          value: 2,
          label: "医疗",
          elabel: "medical treatment",
        },
      ],
      options2: [
        {
          value: 1,
          label: "亚洲",
          elabel: "Asia",
        },
        {
          value: 2,
          label: "欧洲",
          elabel: "Europe",
        },
      ],
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      value1: "",
      value2: "",
      textarea: "",
    };
  },
  methods: {
    submit() {
      const industry = this.options1.find((item) => {
        return item.value == this.value1;
      });
      const region = this.options2.find((item) => {
        return item.value == this.value2;
      });
      const parameter = {
        company: this.input1, //公司
        name: this.input2, //名字
        industry: industry ? industry.label : "", //行业
        region: region ? region.label : "", //地区
        phone: this.input3, //手机
        email: this.input4, //邮箱
        body: this.textarea, //留言
      };
      setGuestbookAdd(parameter).then((res) => {
        console.log(1111);
        if (res.status === 200) {
          Message({
            showClose: true,
            message: "提交成功",
            duration: 3000,
            type: "success",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-main {
  // 第一屏
  .survey-top {
    width: 100%;
    height: 348px;
    background-color: #000;
    position: relative;
    background-image: url("../assets/survey/bg1.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    .title {
      font-size: 24px;
      color: #fff;
      padding-top: 142px;
      margin-left: 120px;
    }
    .tips {
      margin-left: 120px;
      margin-top: 29px;
      line-height: 34px;
      font-size: 18px;
      color: #fff;
      width: 800px;
    }
  }
  // 第二屏
  .survey-second {
    width: 100%;
    height: 639px;
    background: #f7fbff;
    display: flex;
    justify-content: center;
    align-items: center;
    .survey-box {
      display: flex;
      justify-content: center;
      .left {
        width: 400px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;
        margin-right: 32px;
        .box {
          margin-top: 36px;
          margin-bottom: 55px;
          .bold-title {
            font-size: 24px;
            color: #232323;
            font-weight: bolder;
          }
          .tips {
            font-size: 16px;
            color: #666666;
            margin-top: 8px;
          }
        }
      }
      .centre {
        width: 538px;
        height: 405px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .centre-title {
          position: absolute;
          width: 80px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -63px);
          color: #fff;
          font-size: 20px;
          text-align: center;
        }
        .centre-title2 {
          width: 120px;
          transform: translate(-50%, -80px);
        }
      }
      .right {
        width: 400px;
        flex-shrink: 0;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        margin-left: 32px;
        .box {
          margin-top: 36px;
          margin-bottom: 55px;
          .bold-title {
            font-size: 24px;
            color: #232323;
            font-weight: bolder;
          }
          .tips {
            font-size: 16px;
            color: #666666;
            margin-top: 8px;
          }
        }
      }
    }
  }
  // 企业定制服务流程
  .enterprise {
    text-align: center;
    color: #232323;
    width: calc(100% - 240px);
    margin-left: 120px;
    .title {
      font-size: 26px;
      font-weight: bolder;
      margin-top: 72px;
      margin-bottom: 49px;
    }
    .tips {
      font-size: 18px;
      margin-bottom: 5px;
    }
    .boxs {
      display: flex;
      margin-top: 90px;
      justify-content: center;
      .box-item {
        display: flex;
        align-items: center;
        .name-box {
          width: 150px;
          height: 60px;
          background: #ffffff;
          box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.08);
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: #232323;
        }
        .active {
          width: 150px;
          height: 60px;
          background: #0b7cff;
          color: #fff;
        }
        img {
          width: 16px;
          height: 16px;
          margin: 0 21px;
        }
      }
    }
    .boxs2 {
      display: none;
      margin-top: 90px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .box-item {
        .name-box {
          width: 180px;
          height: 60px;
          background: #ffffff;
          box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.08);
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: #232323;
        }
        .active {
          width: 180px;
          height: 60px;
          background: #0b7cff;
          color: #fff;
        }
        img {
          width: 24px;
          height: 24px;
          margin: 20px 0;
        }
      }
    }
  }
  // 你的需求
  .demand {
    width: 100%;
    height: 680px;
    background: #f7fbff;
    margin-top: 90px;
    .title {
      text-align: center;
      font-size: 26px;
      color: #232323;
      font-weight: bolder;
      padding-top: 60px;
      margin-bottom: 46px;
    }
    .boxs {
      display: flex;
      justify-content: space-between;
      width: 909px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 30px;
      .el-input {
        width: 287px;
      }
      .el-select {
        width: 287px;
      }
      .textarea {
        width: 100%;
      }
    }
    button {
      padding: 0;
      margin: 0;
      border: none;
      width: 186px;
      height: 50px;
      background: #0b7cff;
      border-radius: 25px 25px 25px 25px;
      opacity: 1;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 16px;
      margin-top: 40px;
    }
  }
}
</style>
<style scoped>
.textarea >>> .el-textarea__inner {
  min-height: 178px !important;
  /* box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05); */
  border-color: #e5e5e5;
}
.textarea >>> .el-textarea__inner:hover {
  border-color: #0b7cff;
}
.textarea >>> .el-textarea__inner:focus {
  border-color: #0b7cff;
}
.survey-main >>> .el-input__inner {
  border-color: #e5e5e5;
  /* box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.05); */
}
.survey-main >>> .el-input__inner:hover {
  border-color: #0b7cff;
}
.survey-main >>> .el-input__inner:focus {
  border-color: #0b7cff;
}
</style>
<style lang="scss" scoped>
@media only screen and (max-width: 1410px) {
  .survey-main {
    // 第二屏
    .survey-second {
      transform: scale(0.9);
      height: 600px;
    }
  }
}

@media only screen and (max-width: 1265px) {
  .survey-main {
    // 第二屏
    .survey-second {
      transform: scale(0.8);
      height: 500px;
    }
    // 企业定制服务流程
    .enterprise {
      text-align: center;
      color: #232323;
      width: calc(100% - 80px);
      margin-left: 40px;
      .title {
        font-size: 26px;
        font-weight: bolder;
        margin-top: 72px;
        margin-bottom: 49px;
      }
      .tips {
        font-size: 18px;
        margin-bottom: 5px;
      }
      .boxs {
        display: none;
        margin-top: 90px;
        justify-content: center;
        flex-wrap: wrap;
        .box-item {
          display: flex;
          align-items: center;
          .name-box {
            width: 150px;
            height: 60px;
            background: #ffffff;
            box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.08);
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            color: #232323;
          }
          .active {
            width: 150px;
            height: 60px;
            background: #0b7cff;
            color: #fff;
          }
          img {
            width: 16px;
            height: 16px;
            margin: 0 21px;
          }
        }
      }
      .boxs2 {
        display: flex;
      }
    }
  }
}
@media only screen and (max-width: 1130px) {
  .survey-main {
    // 第二屏
    .survey-second {
      transform: scale(0.7);
      height: 400px;
      .tips {
        display: none !important;
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .survey-main {
    // 第一屏
    .survey-top {
      .title {
        font-size: 24px;
        color: #fff;
        padding-top: 142px;
        margin-left: 40px;
        width: calc(100% - 80px);
      }
      .tips {
        margin-left: 40px;
        width: calc(100% - 80px);
      }
    }
    // 第二屏
    .survey-second {
      transform: scale(0.7);
      height: 350px;
      .survey-box {
        display: flex;
        justify-content: center;
        .left {
          width: 200px;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: right;
          margin-right: 32px;
          .box {
            margin-top: 36px;
            margin-bottom: 55px;
            .bold-title {
              font-size: 24px;
              color: #232323;
              font-weight: bolder;
            }
            .tips {
              font-size: 16px;
              color: #666666;
              margin-top: 8px;
            }
          }
        }
        .centre {
          width: 538px;
          height: 405px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .centre-title {
            position: absolute;
            width: 80px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -63px);
            color: #fff;
            font-size: 20px;
            text-align: center;
          }
          .centre-title2 {
            width: 120px;
            transform: translate(-50%, -80px);
          }
        }
        .right {
          width: 200px;
          flex-shrink: 0;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          margin-left: 32px;
          .box {
            margin-top: 36px;
            margin-bottom: 55px;
            .bold-title {
              font-size: 24px;
              color: #232323;
              font-weight: bolder;
            }
            .tips {
              font-size: 16px;
              color: #666666;
              margin-top: 8px;
            }
          }
        }
      }
    }

    // 你的需求
    .demand {
      width: 100%;
      height: auto;
      background: #f7fbff;
      margin-top: 40px;
      .title {
        text-align: center;
        font-size: 26px;
        color: #232323;
        font-weight: bolder;
        padding-top: 40px;
        margin-bottom: 46px;
      }
      .boxs {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0px;
        .el-input {
          width: 300px;
          margin-bottom: 30px;
        }
        .el-select {
          width: 300px;
          margin-bottom: 30px;
        }
        .textarea {
          width: 300px;
          margin-bottom: 30px;
        }
      }
      button {
        padding: 0;
        margin: 0;
        border: none;
        width: 186px;
        height: 50px;
        background: #0b7cff;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .survey-main {
    // 第一屏
    .survey-top {
      .title {
        color: #fff;
        padding-top: 100px;
        margin-left: 20px;
        width: calc(100% - 40px);
        font-size: 20px;
      }
      .tips {
        margin-left: 20px;
        width: calc(100% - 40px);
        font-size: 14px;
        line-height: 24px;
      }
    }
    // 第二屏
    .survey-second {
      transform: scale(0.7);
      height: 350px;
      .survey-box {
        display: flex;
        justify-content: center;
        .left {
          width: 200px;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: right;
          margin-right: 32px;
          .box {
            margin-top: 36px;
            margin-bottom: 55px;
            .bold-title {
              font-size: 20px;
              color: #232323;
              font-weight: bolder;
            }
          }
        }
        .centre {
          width: 400px;
          height: 301px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .centre-title {
            position: absolute;
            width: 80px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -63px);
            color: #fff;
            font-size: 18px;
            text-align: center;
          }
          .centre-title2 {
            width: 120px;
            transform: translate(-50%, -70px);
          }
        }
        .right {
          width: 200px;
          flex-shrink: 0;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          margin-left: 32px;
          .box {
            margin-top: 36px;
            margin-bottom: 55px;
            .bold-title {
              font-size: 20px;
              color: #232323;
              font-weight: bolder;
            }
            .tips {
              font-size: 16px;
              color: #666666;
              margin-top: 8px;
            }
          }
        }
      }
    }

    // 企业定制服务流程
    .enterprise {
      text-align: center;
      color: #232323;
      width: calc(100% - 80px);
      margin-left: 40px;
      .title {
        font-size: 20px;
        font-weight: bolder;
        margin-top: 42px;
        margin-bottom: 49px;
      }
      .tips {
        font-size: 14px;
        margin-bottom: 5px;
      }
      .boxs2 {
        display: flex;
        margin-top: 50px;
      }
    }

    // 你的需求
    .demand {
      width: 100%;
      height: auto;
      background: #f7fbff;
      margin-top: 40px;
      .title {
        text-align: center;
        font-size: 20px;
        color: #232323;
        font-weight: bolder;
        padding-top: 40px;
        margin-bottom: 46px;
      }
      .boxs {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0px;
        .el-input {
          width: 300px;
          margin-bottom: 30px;
        }
        .el-select {
          width: 300px;
          margin-bottom: 30px;
        }
        .textarea {
          width: 300px;
          margin-bottom: 30px;
        }
      }
      button {
        padding: 0;
        margin: 0;
        border: none;
        width: 186px;
        height: 50px;
        background: #0b7cff;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }
  }
}

@media only screen and (max-width: 610px) {
  .survey-main {
    // 第二屏
    .survey-second {
      transform: scale(0.7);
      height: 300px;
      .survey-box {
        display: flex;
        justify-content: center;
        .left {
          width: 150px;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: right;
          margin-right: 10px;
          .box {
            margin-top: 36px;
            margin-bottom: 55px;
            .bold-title {
              font-size: 20px;
              color: #232323;
              font-weight: bolder;
            }
          }
        }
        .centre {
          width: 400px;
          height: 301px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .centre-title {
            position: absolute;
            width: 80px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -63px);
            color: #fff;
            font-size: 18px;
            text-align: center;
          }
          .centre-title2 {
            width: 120px;
            transform: translate(-50%, -70px);
          }
        }
        .right {
          width: 150px;
          flex-shrink: 0;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          margin-left: 10px;
          .box {
            margin-top: 36px;
            margin-bottom: 55px;
            .bold-title {
              font-size: 20px;
              color: #232323;
              font-weight: bolder;
            }
            .tips {
              font-size: 16px;
              color: #666666;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 512px) {
  .survey-main {
    // 第二屏
    .survey-second {
      transform: scale(0.7);
      height: 300px;
      .survey-box {
        display: flex;
        justify-content: center;
        .left {
          width: 100px;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: right;
          margin-right: 10px;
          .box {
            margin-top: 36px;
            margin-bottom: 55px;
            .bold-title {
              font-size: 20px;
              color: #232323;
              font-weight: bolder;
            }
          }
        }
        .centre {
          width: 300px;
          height: 225px;
          position: relative;
          top: 40px;
          img {
            width: 100%;
            height: 100%;
          }
          .centre-title {
            position: absolute;
            width: 80px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -43px);
            color: #fff;
            font-size: 14px;
            text-align: center;
          }
          .centre-title2 {
            width: 120px;
            transform: translate(-50%, -50px);
          }
        }
        .right {
          width: 100px;
          flex-shrink: 0;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          margin-left: 10px;
          .box {
            margin-top: 36px;
            margin-bottom: 55px;
            .bold-title {
              font-size: 20px;
              color: #232323;
              font-weight: bolder;
            }
            .tips {
              font-size: 16px;
              color: #666666;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}
</style>