var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"survey-main"},[_c('div',{staticClass:"survey-top"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("survey.page1.title1")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("survey.page1.title2")))])]),_c('div',{staticClass:"survey-second"},[_c('div',{staticClass:"survey-box"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"bold-title"},[_vm._v(_vm._s(_vm.$t("survey.page2.left.title1")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("survey.page2.left.tips1")))])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"bold-title"},[_vm._v(_vm._s(_vm.$t("survey.page2.left.title2")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("survey.page2.left.tips2")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("survey.page2.left.tips3")))])])]),_c('div',{staticClass:"centre"},[_c('img',{attrs:{"src":require("@/assets/survey/bg2.png")}}),_c('div',{class:[
            'centre-title',
            { 'centre-title2': _vm.$i18n.locale === 'en' },
          ]},[_vm._v(" "+_vm._s(_vm.$t("survey.page2.centre.title1"))+" ")])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"bold-title"},[_vm._v(_vm._s(_vm.$t("survey.page2.right.title1")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("survey.page2.right.tips1")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("survey.page2.right.tips2")))])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"bold-title"},[_vm._v(_vm._s(_vm.$t("survey.page2.right.title2")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("survey.page2.right.tips3")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("survey.page2.right.tips4")))])])])])]),_c('div',{staticClass:"enterprise"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("survey.page3.title1")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("survey.page3.tips1")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("survey.page3.tips2")))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t("survey.page3.tips3")))]),_c('div',{staticClass:"boxs"},_vm._l((6),function(item){return _c('div',{key:item,staticClass:"box-item"},[_c('div',{class:['name-box', { active: item === 1 }]},[_vm._v(" "+_vm._s(_vm.$t(`survey.page3.box${item}`))+" ")]),(item !== 6)?_c('img',{attrs:{"src":require("@/assets/survey/arrow.png")}}):_vm._e()])}),0),_c('div',{staticClass:"boxs2"},_vm._l((6),function(item){return _c('div',{key:item,staticClass:"box-item"},[_c('div',{class:['name-box', { active: item === 1 || item === 6 }]},[_vm._v(" "+_vm._s(_vm.$t(`survey.page3.box${item}`))+" ")]),(item !== 6)?_c('img',{attrs:{"src":require("@/assets/survey/arrow2.png")}}):_vm._e()])}),0)]),_c('div',{staticClass:"demand"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("survey.page4.title1")))]),_c('div',{staticClass:"boxs"},[_c('el-input',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn'
            ? '请输入企业名称'
            : 'Please enter the company name'
        }`},model:{value:(_vm.input1),callback:function ($$v) {_vm.input1=$$v},expression:"input1"}}),_c('el-input',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn' ? '请输入姓名' : 'Please enter your name'
        }`},model:{value:(_vm.input2),callback:function ($$v) {_vm.input2=$$v},expression:"input2"}}),_c('el-select',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn' ? '请选择行业' : 'Please select an industry'
        }`},model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}},_vm._l((_vm.options1),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$i18n.locale === 'zn' ? item.label : item.elabel,"value":item.value}})}),1)],1),_c('div',{staticClass:"boxs"},[_c('el-select',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn' ? '请选择地区' : 'Please select a region'
        }`},model:{value:(_vm.value2),callback:function ($$v) {_vm.value2=$$v},expression:"value2"}},_vm._l((_vm.options2),function(item){return _c('el-option',{key:item.value,attrs:{"label":_vm.$i18n.locale === 'zn' ? item.label : item.elabel,"value":item.value}})}),1),_c('el-input',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn'
            ? '请输入手机号'
            : 'Please enter your phone number'
        }`},model:{value:(_vm.input3),callback:function ($$v) {_vm.input3=$$v},expression:"input3"}}),_c('el-input',{attrs:{"placeholder":`${
          _vm.$i18n.locale === 'zn'
            ? '请输入邮箱'
            : 'Please enter your email address'
        }`},model:{value:(_vm.input4),callback:function ($$v) {_vm.input4=$$v},expression:"input4"}})],1),_c('div',{staticClass:"boxs"},[_c('el-input',{staticClass:"textarea",attrs:{"type":"textarea","rows":2,"placeholder":`${
          _vm.$i18n.locale === 'zn'
            ? '留下您的需求，获取解决方案'
            : 'Leave your needs and obtain solutions'
        }`},model:{value:(_vm.textarea),callback:function ($$v) {_vm.textarea=$$v},expression:"textarea"}})],1),_c('button',{on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$i18n.locale === "zn" ? "立即提交" : "Submit"))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }